import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"validator",attrs:{"slim":""}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Видео")]),_c(VSpacer),_c(VSwitch,{attrs:{"label":"Активен","hide-details":""},model:{value:(_vm.entity.enabled),callback:function ($$v) {_vm.$set(_vm.entity, "enabled", $$v)},expression:"entity.enabled"}})],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c(VTextField,{attrs:{"label":"Название видео","outlined":"","error-messages":errors[0]},model:{value:(_vm.entity.name),callback:function ($$v) {_vm.$set(_vm.entity, "name", $$v)},expression:"entity.name"}})]}}])}),_c('div',{staticClass:"videos__video-container"},[_c('video',{attrs:{"src":_vm.imageSrc(_vm.entity.link),"height":"600px","controls":""}})]),_c(VFileInput,{attrs:{"label":"Видео","outlined":"","prepend-icon":"mdi-image"},on:{"change":_vm.setVideo}}),_c(VImg,{attrs:{"src":_vm.imageSrc(_vm.entity.screenshot)}}),_c(VFileInput,{attrs:{"label":"Превью","outlined":"","prepend-icon":"mdi-image"},on:{"change":_vm.setScreenshot}}),_c(VRow,{staticClass:"mt-4 mx-0",attrs:{"align":"center","justify":"space-between"}},[_c(VBtn,{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.save}},[_vm._v("Сохранить")]),(_vm.entity._id)?_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm.remove}},[_vm._v("Удалить")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }