<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>Видео</v-toolbar-title>
                <v-spacer/>
                <v-switch
                    v-model="entity.enabled"
                    label="Активен"
                    hide-details
                />
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.name"
                    label="Название видео"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <div class="videos__video-container">
                <video
                    :src="imageSrc(entity.link)"
                    height="600px"
                    controls
                ></video>
            </div>
            <v-file-input
                label="Видео"
                outlined
                prepend-icon="mdi-image"
                @change="setVideo"
            />
            <v-img :src="imageSrc(entity.screenshot)" />
            <v-file-input
                label="Превью"
                outlined
                prepend-icon="mdi-image"
                @change="setScreenshot"
            />

            <v-row
                align="center"
                justify="space-between"
                class="mt-4 mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">Сохранить</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">Удалить</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc } from '../../helpers';
    import { SUBJECT, SUBJECT_TITLE } from '../../vars';

    export default {
        name: 'VideosEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            SUBJECT,
            SUBJECT_TITLE,
            imageSrc,
            valid: true,
        }),
        computed: {
            ...mapState('videos', ['entity']),
            pages() {
                const pages = [];
                for(const key in SUBJECT_TITLE) {
                    pages.push({
                        value: key,
                        title: SUBJECT_TITLE[key]
                    });
                }
                return pages;
            }
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('videos/get', { id: this.$route.params.id });
            } else {
                await store.commit('videos/CLEAR_ENTITY');
            }
        },
        methods: {
            async setVideo(file) {
                this.entity.link = await store.dispatch('files/upload', { file });
            },
            async setScreenshot(file) {
                this.entity.screenshot = await store.dispatch('files/upload', { file });
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('videos/SET_ENTITY', this.entity);
                    const response = await store.dispatch('videos/save');
                    if(response.error) {
                        alert(response.error);
                    } else {
                        await this.$router.push({ name: 'videos-list' });
                    }
                }
            },
            async remove() {
                await store.dispatch('videos/delete', { id: this.entity._id });
                await this.$router.push({ name: 'videos-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
    .videos__content__block {
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #cecece;
        border-radius: 4px;
    }
    .videos__video-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
    }
</style>
